<template>
	<div class="section_right">
		<div class="sub_top">
            <div class="sub_title"><i class="far fa-users text-primary"></i>{{ $t('partner.1') }}</div>
		</div>
		<!--sub_top end-->
		<div class="section_right_inner">
			<!--section_right_inner-->

            <div class="flex-grow-1 d-flex border border-gray-relative-200 rounded-3 bg-gray-relative-100 bg-opacity-50 flex-between-center px-2 py-1 mb-3">
                <div class="form-floating text-body w-100 pe-2">
                    <input type="text" class="bg-transparent flex-grow-1 form-control px-0 border-0" :value="share_link">
                    <label class="px-0 small text-body" for="address">{{$t('landing.49')}}</label>
                </div>
                <span class="bg-primary text-white small flex-center-center p-3 rounded-3 flex-shrink-0 cursor-pointer w-px-100" @click="RecomUrl">{{$t('landing.51')}}</span>
            </div>
            
            <p class="mypage_section01_t mb-3">{{$t("partner.3")}}</p>


            <ul v-if="list&&list.length>0">
                <li v-for="(item, index) in list" :key="index" class="mb-2 shadow-sm">
                    <div  class="bg-gray-relative-0 w-100 flex-start-center p-3 rounded cursor-pointer">
                        <div class="me-4 w-px-50 h-px-50 position-relative bg-gray-relative-0">
                            <span class="border-gray-relative-400 shadow-sm position-absolute start-0 bottom-0 translate-middle-x text-body rounded-circle w-px-20 h-px-20 bg-body zindex-1 flex-center-center fs-px-11 fw-bold">{{item.count}}</span>
                            <img :src="require('@/assets/logo.png')" :alt="item.name" class="w-px-50 h-px-50 object-fit-contain">
                            <img :src="`/assets/img/location/${item.location}.gif`" class="shadow-sm w-px-20 h-px-20 position-absolute bottom-0 rounded-circle mx-0 object-fit-cover bg-white border border-gray-relative-200" style="right:-10px">
                        </div>
                        <div class="flex-between-center w-100">
                            <div class="lh-1">
                                <h6 class="fw-bold text-gray-relative-900">{{ item.nick_name }}</h6>
                                <small class="fs-px-12 text-gray-500">{{ item.email }} </small>
                            </div>
                            <div class="lh-1 text-end">
                                <h6 class="fw-bold text-gray-relative-900">+ {{ item.total }} </h6>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div v-else class="empty_data">
                <i class="fas fa-ellipsis"></i>
            </div>
		</div>
		<!--section_right_inner end-->
	</div>
	<!--section_right end-->
</template>
<script>
const CryptoJS = require("crypto-js");

export default {
	data(){
		return{
            share_link : '',
			list : [
            ],
            langImg: ['korea.png', 'usa.png', 'jpn.png', 'cha.png', 'Iitaly.png'],
		}
	},
	created(){
		this.GetPartnerList()
        this.GetShareLink();
	},
	methods : {
        GetShareLink(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post(`/member/landing/GetShareInfo`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            const code = body.info.code;
                            this.share_email = body.info.code;
                            const site = process.env.VUE_APP_FRONT_URL;
                            this.share_link = `${site}/${this.$i18n.locale}/sign/${code}`;

                        }
                    }

                }
            ).catch(() => {});
            
        },
        RecomUrl(){
            const code = this.share_email;
            const site = process.env.VUE_APP_FRONT_URL;
            this.link = `${site}/${this.$i18n.locale}/sign/${code}`;
            
            this.$copyText(this.link).then(()=> {
                alert(this.$t('deposit.8'))
            }	
            )
        },
		GetPartnerList(){
            const body = {};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            this.$http.post(`/member/partner/GetPartnerList`,{req}).then(
                res => {
                    if(res.status==200){
                        if(res.data.code=='200'){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            this.list = body.list;

                        }
                    }

                }
            ).catch(() => {});
		},
        numberWithCommas(x) {
			var parts=x.toString().split(".");
			return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
		},
	}
}
</script>
<style>
</style>