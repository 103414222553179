<template>
	<div class="section_right main">
		<div class="section_right_inner">
			<div class="pt-3">
				<!-- class="background" -->
				<div>
					<h6 class="mb-2 fw-bold text-gray-relative-900 flex-start-center">
						<!-- <img src="@/assets/logo_s.png" width="18" class="me-2"> -->
						<span>{{$t('dashboard.27')}}</span>
					</h6>
					<div class="sponsorship_box mb-3 bg-gray-relative-0" style="width:100%">
						<ul>
							<!--  @click="GotoMove('/'+$i18n.locale+'/point/exchange')" -->
							<li @click="warningPp()" class="mb-2 shadow-sm">
								<div  class="bg-gray-relative-0 w-100 flex-start-center p-3 rounded cursor-pointer" @click="GotoMoveCoin(item.symbol)">
									<div class="me-3 rounded-circle flex-shrink-0 overflow-hidden">
										<img src="@/assets/logo_s.png" width="42">
									</div>
									<div class="flex-between-center w-100">
										<div class="lh-1">
											<h6 class="fw-bold text-gray-relative-900">JESUS153</h6>
											<small class="fs-px-12 text-gray-500">= {{ numberWithCommas(point_price) }} USD</small>
										</div>
										<div class="lh-1 text-end">
											<h6 class="fw-bold text-gray-relative-900">{{numberWithCommas(point)}} JESUS153</h6>
											<small class="fs-px-12 text-gray-500">JESUS153 1 = {{numberWithCommas(rate)}} USD</small>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="notice_table04 mb-5" style="height:200px">
					<div style="text-align: left;"><small class="fs-px-12 text-gray-500 cursor-pointer" @click="GotoMove('/'+$i18n.locale+'/point/history')">+ {{ $t('etc.3') }}</small></div>
					<table style="width:100%" class="main_table02">
						<thead>
							<tr>
								<th>{{$t('assets.2')}}</th>
								<th>{{$t('assets.4')}}</th>
								<th>{{$t('assets.15')}}</th>
								<th>{{$t('assets.14')}}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in point_history" :key="index">
								<td>{{item.date}}</td>
								<td>{{numberWithCommas(item.point)}}</td>
								<td>{{CheckType(item.type)}}</td>
								<td>{{CheckTarget(item.target)}}</td>
							</tr>
						</tbody>
					</table>
				</div>	
				<div>				
					<h6 class="mb-2 fw-bold text-gray-relative-900 flex-start-center">
						<img src="@/assets/logo_s.png" width="18" class="me-2">
						<span>{{$t('dashboard.28')}}</span>
					</h6>
					<div class="sponsorship_box mb-3 bg-gray-relative-0" style="width:100%">
						<ul>
							<li   @click="GotoMove('/'+$i18n.locale+'/coin/send')">
								<div  class="bg-gray-relative-0 w-100 flex-start-center p-3 rounded cursor-pointer" @click="GotoMoveCoin(item.symbol)">
									<div class="me-3 rounded-circle flex-shrink-0 overflow-hidden">
										<img src="@/assets/logo_s.png" width="42">
									</div>
									<div class="flex-between-center w-100">
										<div class="lh-1">
											<h6 class="fw-bold text-gray-relative-900">JESUS153</h6>
											<small class="fs-px-12 text-gray-500">= {{ numberWithCommas(coin_price) }} USD</small>
										</div>
										<div class="lh-1 text-end">
											<h6 class="fw-bold text-gray-relative-900">{{numberWithCommas(exchange)}} JESUS153</h6>
											<small class="fs-px-12 text-gray-500">JESUS153 1 = {{numberWithCommas(rate)}} USD</small>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="notice_table04 mb-5" style="height:200px">
					<div style="text-align: left;"><small class="fs-px-12 text-gray-500 cursor-pointer" @click="GotoMove('/'+$i18n.locale+'/coin/history')">+ {{ $t('etc.3') }}</small></div>
					<table style="width:100%" class="main_table02">
						<thead>
							<tr>
								<th>{{ $t('assets.2') }}</th>
								<th>{{ $t('assets.4') }}</th>
								<th>{{ $t('assets.15') }}</th>
								<th>{{ $t('history.9') }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in coin_history" :key="index">
								<td>{{item.date}}</td>
								<td>{{numberWithCommas(item.balance)}}</td>
								<td>{{CheckCoinType(item.type)}}</td>
								<td>{{CheckCoinStatus(item.status)}}</td>
							</tr>
						</tbody>
					</table>
				</div>


				<div class="mb-5">
					<h6 class="mb-2 fw-bold text-gray-relative-900">
						<span>{{$t('dashboard.29')}}</span>
					</h6>
					<ul class="ps-0">
						<li v-for="(item,index) in coin_list" :key="index" class="mb-2 shadow-sm">
							<div  class="bg-gray-relative-0 w-100 flex-start-center p-3 rounded cursor-pointer" @click="GotoMoveCoin(item.symbol)">
								<div class="me-3 rounded-circle flex-shrink-0 overflow-hidden">
									<img :src="item.img" :alt="item.name" width="42">
								</div>
								<div class="flex-between-center w-100">
									<div class="lh-1">
										<h6 class="fw-bold text-gray-relative-900">{{ item.name }}</h6>
										<small class="fs-px-12 text-gray-500">= {{ CheckBalance(item.balance,item.rate) }} USD</small>
									</div>
									<div class="lh-1 text-end">
										<h6 class="fw-bold text-gray-relative-900">{{ numberWithCommas(item.balance) }} {{ item.symbol }}</h6>
										<small class="fs-px-12 text-gray-500">{{item.symbol}} 1 = {{numberWithCommas(item.rate)}} USD</small>
									</div>
								</div>
							</div>
							<!-- <router-link :to=""> -->
								
							<!-- </router-link> -->
						</li>
					</ul>
				</div>


				<div class="notice_table04">
					<div style="text-align: left;"><small class="fs-px-12 text-gray-500 cursor-pointer" @click="GotoMove('/'+$i18n.locale+'/coin/exchange')">+ {{ $t('etc.3') }}</small></div>
					<div class="card mb-2 border-0 rounded-0 border-bottom border-gray-relative-300" v-for="(item,index) in exchange_history" :key="index">
						<div class="card-body px-0">
							<div class="progress bg-gray-relative-300">
								<div class="progress-bar" role="progressbar" :style="{'width': item.percent_text}" :aria-valuenow="item.percent" aria-valuemin="0" aria-valuemax="100">{{numberWithCommas(item.use_balance)}}</div>
							</div>
							<div class="d-flex flex-between-center mt-1">
								<div><h6 class="fw-bold text-gray-relative-900">{{ $t('coin.101') }}</h6></div>
								<div><small class="fs-px-12 text-gray-500">{{item.unlock_date}} </small><small class="fs-px-12 text-gray-500" v-if="item.day >0 ">( D-{{ item.day }} ) </small></div>
							</div>
							<div class="d-flex flex-between-center mt-1">
								<div><h6 class="fw-bold text-gray-relative-900">{{ $t('history.8') }}</h6></div>
								<div><small class="fs-px-12 text-gray-500">{{numberWithCommas(item.trans_balance)}}</small></div>
							</div>
							<div class="d-flex flex-between-center mt-1">
								<div><h6 class="fw-bold text-gray-relative-900">{{ $t('coin.102') }}</h6></div>
								<div><small class="fs-px-12 text-gray-500">{{numberWithCommas(item.day_balance)}}</small></div>
							</div>
						</div>
					</div>
					
				</div>	
			</div>
		</div>

		<Popup ref="popup" />
	</div>
</template>
<script>
const CryptoJS = require("crypto-js");
const exactMath = require('exact-math');
import Popup from '@/components/common/Popup.vue'
	export default {
		components: {
			Popup
		},
		data(){
			return{
				site : '',
				ppOpen : false,
				id : this.$store.state.id,
				name : this.$store.state.name,
				level : '',
				point_history : [],
				rate : '',
				point : '',
				coin : '',
				point_price : '',
				coin_price : '',
				coin_history : [],
				exchange_history : [],
				exchange : '',
				coin_list: [],
			}
		},
		mounted(){
			this.GetDashboardInfo()
			// this.GetMySales()
			// this.GetMyCoin();
			// this.GetMyAssest();
			// this.GetMyExtra()
			// this.GetExtraChart();
			// this.GetBonusChart();
			
			// this.GetNoticePopup();
            this.GetCoinList()			
		},
		methods : {
			warningPp(){
				this.$alert(this.$t('deposit.10'))
			},
			GotoMove(link){
				this.$router.push({path : link});
			},
			
			RecomUrl(line){
				const code = this.code;
				const site = process.env.VUE_APP_FRONT_URL;
				this.link = `${site}/${this.$i18n.locale}/sign/${code}`;
				
				this.$copyText(this.link).then(()=> {
					alert(this.$t('deposit.8'))
				}	
				)
			},
			GetDashboardInfo(){
				
				const body = {};
				const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

				this.$http.post(`/member/dashboard/GetDashboardInfo`,{req}).then(
					res => {
						if(res.status==200){
							if(res.data.code=='200'){
								const e_body = res.data.body;
								const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
								const d_res = bytes.toString(CryptoJS.enc.Utf8);
								const body = JSON.parse(d_res)
								
								this.rate = body.rate;
								this.point = body.point;
								this.point_price = exactMath.mul(this.point,this.rate);
								this.point_history = body.point_history;
								this.coin_history = body.coin_history;
								this.exchange_history = body.exchange_history;
								this.exchange = body.exchange;
								this.coin = body.coin;
								this.coin_price = exactMath.mul(this.exchange,this.rate);

							}else if(res.data.code=='9999'){
								// 
								this.$store.dispatch('SETLOGOUT').then(
									()=>{
										window.location.href= `/${this.$i18n.locale}/landing`;
									}
								)
							}
						}

					}
				).catch(() => {});
			},
			CheckType(type){
				
				if(type=='P'){
					return this.$t('assets.20')
				}else if(type=='M'){
					return this.$t('etc.100')
				}
			},
			CheckCoinType(type){
				
				if(type=='P'){
					return this.$t('assets.17')
				}else if(type=='M'){
					return this.$t('withdraw_point.11')
				}
			},
			CheckCoinStatus(status){
				
				if(status=='Y'){
					return this.$t('etc.101')
				}else if(status=='H'){
					return this.$t('etc.102')
				}else if(status=='C'){
					return this.$t('join.18')
				}
			},
			CheckTarget(target){
				if(target =='J'){
					return this.$t('join.100')
				}else if(target =='JR'){
					return this.$t('join.101')
				}else if(target =='E'){
					return this.$t('sidebar.14')
				}

			},
			numberWithCommas(x) {
				var parts=x.toString().split(".");
				return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
			},
			


			GotoMoveCoin(symbol){
                if(symbol !='NCC' && symbol !='NCP'){
                    this.$router.push({path : `/${this.$i18n.locale}/CoinHistory/${symbol}`})
                }else{
                    if(symbol =='NCP'){
                        this.$router.push({path : `/${this.$i18n.locale}/AssetsHistory`})
                    }else if(symbol =='NCC'){
                        this.$router.push({path : `/${this.$i18n.locale}/NccAssetsList`})
                    }
                    
                }  
            },
            
            CheckBalance(a,b){
                const p = exactMath.mul(a,b);

                return p

            },
            GetCoinList(){
                
                const body = {};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetCoinList`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)

                                this.coin_list = body.list;

                                for (const [index,el] of body.list.entries()) {
                                    if(el.symbol !='JEJUS'){
                                        
                                        this.GetCoinBalance(index,el.symbol);    
                                    }
                                    
                                }
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
			
            GetCoinBalance(index,symbol){
                
                const body = {symbol};
                const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
                this.$http.post(`/member/coin/GetCoinBalance`,{req}).then(
                    res => {
                        if(res.status==200){
                            if(res.data.code=='200'){
                                const e_body = res.data.body;
                                const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                                const body = JSON.parse(d_res)

                                this.coin_list[index].balance = body.balance;
                            }else if(res.data.code=='9999'){
                                this.$store.dispatch('SETLOGOUT').then(
                                    ()=>{
                                        window.location.href= `/${this.$i18n.locale}/login`;
                                    }
                                )
                            }
                        }
                    }
                ).catch(() => {});
            },
		},
		
	}
	
</script>
<style scoped>
	.main_balance_total span {
		display: inline-block;
		font-size: 24px;
	}
	.span001{
		background: transparent;
		padding-left: 0px;
		font-size: 14px;
		margin-left: 7px;
	}
	.red {
		color: red;
	}
	.blue{
		color: blue;
	}

	.active_line{
		background-color: yellow !important;
		color: #333;
	}
	.sponsorship_box:nth-child(2) {
		position: relative;
	}
	.more_badge{
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 10px;
		background-color: #eee;
		padding: 4px 6px;
		border-radius: 6px;
		z-index: 9;
	}
	.theme01 .more_badge{
		background-color: #333;
	}

	.copy-icon{
		background-color: #6565e6;
		padding: 8px;
		position: absolute;
		opacity: .5;
		/* top: 50%;
		transform: translateY(-50%); */
		/* left: 5px; */
		right: 5px;
		bottom: 10px;
		
	}
</style>
<style>
.apexcharts-legend {
	max-height: 30px !important;
}
.apexcharts-text.apexcharts-pie-label{
	text-shadow: none !important;
	filter: drop-shadow(0);
}
</style>